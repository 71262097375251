<template>
    <div class="bg-bggray">
        <div
            class="py-12 md:py-20 lg:py-24 lg:w-4/5 md:w-3/5 w-10/12 inset-x-0 m-auto text-justify text-base leading-relaxed">
            <h5 class="text-center font-bold font-blackMango text-lg md:text-sectionTitle tracking-wider mb-2 md:mb-4">INTRODUCTION
            </h5>
            <hr class="mb-2 md:mb-5">
            <p><span> {{ Client }} </span><span> is fully GDPR compliant, and completely transparent about the way your
                    data is collected and used and will back up your right to know more about how your data is
                    treated.</span>
            </p>
            <p><span>We use cookies and other technologies to gather information in order to both improve your browsing
                    experience on our website and for statistical analysis.</span>
            </p>
            <p><span>Find out more on how to refuse sharing this information:</span></p>
            <p><b>Google Analytics:</b><span> IP Address, Demo Data, Used Technology, Behaviours, Visited Pages and
                    Duration.</span>
            </p>
            <p class="text-left ">
                <i><span>Deactivation can be done by using the following </span></i><i><span>link</span></i><span>
                </span><i><span>(</span></i><a
                    href="http://tools.google.com/dlpage/gaoptout"><i><span>http://tools.google.com/dlpage/gaoptout</span></i></a><i><span>)</span></i>
            </p>

            <h5 class="text-left font-bold mb-1 font-blackMango text-md tracking-wide mt-5">PRIVACY NOTICE</h5>
            <p class="mb-1 text-left">
                <span>Data Controller:</span>
                <span> {{ Client }} </span>
            </p>
            <p class="mb-1 text-left">
                <span>Company number: </span>
                <span>{{ NIF }}</span>
            </p>
            <p class="mb-1 text-left">
                <span>Address: </span>
                <span>{{ Address }}</span>
            </p>
            <p class="mb-1 text-left">
                <span>E-mail address: </span>
                <span>{{ Email }}</span>
            </p>
            <p class="mb-1 text-left">
                <span>Phone number: </span>
                <span>(+351) {{ Phone }}</span>
            </p>
            <p class="mb-1 text-left">
                <span>{{ Aviso }}</span>
            </p>

            <h5 class="mt-5 font-bold text-price font-blackMango tracking-widest">INDEX</h5>
            <ol class="mb-0 list-decimal list-inside">
                <li><a href="#introduction">Introduction</a></li>
                <li><a href="#principles">Principles Relating To Processing Of Personal Data</a></li>
                <li><a href="#categories">Type Of Personal Data Collected</a></li>
                <li><a href="#information">How We Use Information We Collect and Receive</a></li>
                <li><a href="#contents">Contents and Services linked via Website</a></li>
                <li><a href="#storage">Storage Period</a></li>
                <li><a href="#processing">Legitimate Grounds for Processing</a></li>
                <li><a href="#rights">Your Rights</a></li>
                <li><a href="#security">Security and Confidentiality</a></li>
                <li><a href="#sharing">Sharing Information</a></li>
                <li><a href="#location">Location and Transfer</a></li>
                <li><a href="#complaint">Filing a Complaint</a></li>
                <li><a href="#amendments">Amendments to the Privacy Notice of This Website</a></li>
                <li><a href="#contacts">Contact</a></li>
            </ol>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="introduction"><strong>A.
                    INTRODUCTION</strong></h4>
            <p><span>This Privacy Notice is intended to inform website visitors and service users (&ldquo;Users&rdquo;,
                    &ldquo;you&rdquo;, &ldquo;your&rdquo;) about the rules and procedures adopted by </span><span>
                    {{ Client }} </span><span> (&ldquo;</span><span> {{ Client }} </span><span> &rdquo;, "we", "us",
                    "our") regarding the processing of the data collected when accessing the </span><span> {{ Website }}
                </span><span> website and using the functionalities and services (collectively, our
                    &ldquo;Services&rdquo;) it provides.</span>
            </p>
            <p><span>This policy applies where we act as a data controller towards your personal data, meaning where we
                    determine the purpose and mean of the processing of personal data.</span>
            </p>
            <p><span>By accessing and using our Services you will be asked to agree to the collection and subsequent
                    processing of your personal data. Any information you provide by which you may be identified when
                    using our Services will only be used in accordance with this Privacy Notice and for the specific
                    purposes indicated herein, which is effective as of 01.01.2020.</span>
            </p>
            <p><b>You should read this document carefully and only access the website and/or submit
                    information if
                    you
                    agree with its provisions.</b></p>
            <p>
                <span>This Privacy Notice only covers data processing carried out by </span><span> {{ Client }}
                </span><span>. The Privacy Notice does not address, and we are not responsible for, the privacy
                    practices of any third parties, also in cases where Services include hyperlinks to third
                    parties&rsquo; websites or when cookies are set by third parties.</span>
            </p>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="principles"><strong>B. PRINCIPLES
                    RELATING TO PROCESSING OF PERSONAL
                    DATA</strong>
            </h4>
            <p><span>We are committed to safeguarding your privacy and processing your data in a lawful, fair and
                    transparent manner.&nbsp;</span>
            </p>
            <p><span>We collect data from you for specified, explicit and legitimate purposes explained below and we do
                    not further process your data in a manner that is incompatible with those purposes.&nbsp;</span>
            </p>
            <p><span>We take every reasonable step to ensure that the data we collect, use or process in any other way
                    is accurate, complete, up-to-date, necessary and limited to the purposes for which they are
                    processed and are erased or rectified without delay when specific purposes are fulfilled.</span>
            </p>
            <p><span>We do not store your personal data in a form which permits identification of data subjects longer
                    than is legally permitted and necessary for the purposes for which the personal data are
                    processed.&nbsp;</span>
            </p>
            <p><span>We process your data in a manner that ensures appropriate security, confidentiality and integrity
                    of your personal data, including protection against unauthorised or unlawful processing and against
                    accidental loss, destruction or damage, using appropriate technical or organisational measures.
                    These measures include the maintenance of a system of administrative, physical and technical
                    safeguards to protect personal data.</span>
            </p>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="categories"><strong>C. TYPE OF
                    PERSONAL DATA COLLECTED</strong></h4>
            <p><span>We may collect, use and process the following type of data:</span></p>
            <p><b>Notification data:</b><span> If you subscribe to our Newsletter, which contains information about the
                    products and services available on our website, you will be asked for an</span><span>email address
                    which will be stored on a third party e-mail service provider&rsquo;s server (Mailchimp);</span>
            </p>
            <p><b>Contact request data:</b><span> In order to send out a contact request, you will need to fill out our
                    online form. Through this form we collect personal data, including your name, company name, email
                    address, phone number (optional), date and hour you submitted the form.</span>
            </p>
            <p><b>Technical data: </b><span>We receive technical information from Google Analytics such as the pages you
                    visited, the browser you are using, the types of device you are using to access our platform and
                    demographic data. We will also monitor duration of each page you visit. Although we do not normally
                    use technical data to identify individuals, sometimes individuals can be recognized from it, either
                    alone or when combined or linked with user data. In such situations, technical data can also be
                    considered to be personal data under applicable law and we will treat the combined data as personal
                    data.</span>
            </p>
            <p><span>We use various technologies to collect and process technical data in relation to you, including
                    cookies. Cookies are small text files stored on Users&rsquo; computer and smartphone by the Internet
                    browser. Insofar as those cookies are not strictly necessary for the provision of our website and
                    services, we will ask you to consent to our use of cookies when you first visit our website. Cookies
                    allow us to calculate the aggregate number of people requesting to be contacted.</span>
            </p>
            <p><span>Our advertising cookies allow us to deliver targeted advertising to people who visit our websites
                    (online behavioural advertising OBA). Users may choose to set their web browser to refuse cookies,
                    or to alert when cookies are being sent. This can usually be done through Internet browser&rsquo;s
                    settings. Information about how to manage cookies can be found online. Some parts of our Services
                    may not function properly if you prefer not to accept the use of cookies.</span>
            </p>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="information"><strong>D. HOW WE USE
                    INFORMATION WE COLLECT AND
                    RECEIVE</strong>
            </h4>
            <p><b>Communicating with you:</b><span> We use Notification Data about you for the purpose to send you our
                    Newsletters via Mailchimp.</span>
            </p>
            <p><b>Improving user experience:</b><span> We use Contact Request Data, Ongoing Usage Data and Technical
                    Data we receive for statistical purposes and in order to tailor-made and improve your experience on
                </span><span> {{ Client }} </span><span> website and using our Services, including to:</span>
            </p>
            <ul>
                <li>
                    <span>monitor and analyse trends, usage, and activities in connection with our website;</span>
                </li>
                <li><span>to enhance our Services and the use thereof;</span></li>
                <li><span>perform tracking of the use of our Services;</span></li>
            </ul>
            <p><b>Legal
                    requirement:</b><span> We may also process information to comply with a legal requirement.</span>
            </p>
            <p><span>You should note that, any mandatory personal data being requested is necessary to provide Services
                    you may request via this website and in order to comply with the obligation arising from applicable
                    law and regulations.</span>
            </p>
            <p><span>The disclosure of your data beyond what is necessary in order to provide the requested Services or
                    to comply with the legal or contractual requirements, is optional and has no implication on the use
                    of this</span><span>website. We will inform you whether the insertion of your personal data is
                    mandatory or optional by placing an asterisk (*) next to the information that is mandatory.</span>
            </p>
            <p><span>Accordingly, any refusal to provide us with mandatory personal data, may prevent the processing of
                    contact request. We reserve the right to decline your contact request shall you decide to not
                    provide us the necessary personal data. Failure to provide us any optional data will not be a
                    barrier to the processing of our Services.</span>
            </p>
            <p>Remember the Windows XP operating system? You can work with it online at <a href="https://geekprank.com/"
                    rel="noopener" target="_blank">GeekPrank</a>
                and trick your friends.</p>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="contents"><strong>E. CONTENTS AND
                    SERVICES LINKED VIA WEBSITE</strong>
            </h4>
            <p>
                <span>This website may contain links, directories and search tools that allow you to access
                    website</span><i><span>s</span></i><span> of external entities that are not controlled by
                </span><span> {{ Client }} </span><span>. We are not responsible for and do not endorse any linked
                    websites. Linked website</span><i><span>s</span></i><span> may have their own terms of use and
                    privacy policies that govern access to and use thereof, of which </span><span> {{ Client }}
                </span><span> is wholly unaware.</span>
            </p>
            <p><span>We are not responsible for any loss, damage or obligation that is incurred as a result of visiting
                    a website linked to this website, and </span><span> {{ Client }} </span><span> is not associated
                    with any of the said website</span><i><span>s</span></i><span>. If you accessed this website via an
                    external link, your access to and use of this website is governed by these terms and this privacy
                    policy and not by the terms and policies of the external entity from which you linked.</span>
            </p>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="storage"><strong>F. STORAGE
                    PERIOD</strong></h4>
            <p><span>We do not store the personal data for longer than is legally permitted and necessary for the
                    related processing purposes. The storage period depends on the type of personal data, the purposes
                    and the applicable law and is therefore different for each usage.</span>
            </p>
            <p><span>Typically, we store your personal data for as long as you are using our Services or for as long as
                    we have another purpose to do so and, thereafter, for no longer than the required period or
                    permitted by law or necessary for internal reporting and reconciliation purposes.&nbsp;</span>
            </p>
            <p><span>We erase personal data after the above described storage period or when you request us to erase
                    your personal data.</span>
            </p>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="processing"><strong>G. LEGITIMATE
                    GROUNDS FOR PROCESSING</strong></h4>
            <p><span>We process personal data to pursue our legitimate interest to run, maintain and develop our
                    business. Furthermore, we process personal data to comply with our legal obligations.&nbsp;</span>
            </p>
            <p><span>In some parts of our Services, we might request you to consent for the processing of your personal
                    data for specific purposes. In that event, you may withdraw your consent at any time.</span>
            </p>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="rights"><strong>H. YOUR
                    RIGHTS</strong></h4>
            <p><b>Right to access:</b><span> You may contact us to get confirmation as to whether we are processing your
                    personal data. Where we do process your personal data, we will inform you of what type of personal
                    data we treat towards your account, the processing purposes, the type of recipients to whom personal
                    data have been or will be disclosed and the forecasted storage period or criteria to determine that
                    period.</span>
            </p>
            <p><b>Right to withdraw consent:</b><span> In case our processing is based on a consent granted by you, you
                    may withdraw the consent at any time by contacting us. Withdrawing a consent may lead to fewer
                    possibilities to use our Services.</span>
            </p>
            <p><b>Right to rectification:</b><span> You have the right to have inaccurate or incomplete personal data we
                    store about you rectified or completed.&nbsp;</span>
            </p>
            <p><b>Right to object:</b><span> In case our processing is based on our legitimate interest to run, maintain
                    and develop our business, you have the right to object at any time to our processing. We shall then
                    no longer process your personal data unless for the provision of our Services or if we demonstrate
                    other compelling legitimate grounds for our processing that override your interests, rights and
                    freedoms or for legal claims. Notwithstanding any consent granted beforehand for direct marketing
                    purposes, you have the right to prohibit us from using your personal data for direct marketing
                    purposes, by contacting us or by using the functionalities of the Services or unsubscribe
                    possibilities in connection with our direct marketing messages.</span>
            </p>
            <p><b>Right to restriction of processing:</b><span> You have the right to obtain from us restriction of
                    processing of your personal data, as foreseen by applicable data protection law. Restriction of
                    processing may lead to fewer possibilities to use our Services.&nbsp;</span>
            </p>
            <p><b>Right to data portability:</b><span> You have the right to receive your personal data from us in a
                    structured, commonly used and machine-readable format and to independently transmit those data to a
                    third party, in case our processing is based on your consent and carried out by automated
                    means.&nbsp;</span>
            </p>
            <p><b>Right to erasure:</b><span> You have the right to have personal data we process about you erased from
                    our systems if the personal data are no longer necessary for the related purposes, if we have
                    unlawfully processed the personal data or if the you object to processing for direct marketing. You
                    furthermore have the right to erasure if you withdraw consent or object to our processing as meant
                    above, unless we have a legitimate ground to not erase the data. We may not immediately be able to
                    erase all residual copies from our servers and backup systems after the active data have been
                    erased. Such copies shall be erased as soon as reasonably possible.</span>
            </p>
            <p><span>In order to exercise your rights freely and at any time, in accordance with the applicable law and
                    regulations, please send your request to us at </span><span> {{ Email }} </span><span>.&nbsp;</span>
            </p>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="security"><strong>I. SECURITY AND
                    CONFIDENTIALITY</strong></h4>
            <p><span>We engage, as the entity responsible for the management of the website, our best effort to ensure
                    the privacy of your collected data. The security and privacy of the data collected is a matter of
                    extreme importance to us. We, therefore, use security technologies and procedures to protect the
                    information collected from unauthorised access, use or disclosure. No security measure, however, can
                    ever be completely secure, therefore we cannot guarantee that these measures will stop anyone from
                    trying to get around the privacy or security procedures through unforeseen and/or illegal
                    means.</span>
            </p>
            <p>
                <span>Access to the data collected or received is limited to employees of </span><span> {{ Client }}
                </span><span> and any subcontractors and third parties that require access to your personal data in
                    order to supply the Services requested by you.</span>
            </p>
            <p><span>Access will be provided to public bodies or public authorities, where this is necessary in order to
                    comply with a legal or contractual obligation of </span><span> {{ Client }} </span><span>, i.e.
                    pursuant to a court order or an administrative order, provided that the same is issued by a proper
                    authority.</span>
            </p>
            <p><span>You should note that the data collected on the website may, when being collected, be available and
                    unsecured on the web, so that there is a risk that it may be viewed and used by unauthorised third
                    parties.&nbsp;</span>
            </p>
            <p><span>If we have any doubt that the security of your personal information in our possession or control
                    may have been compromised or could be at risk, we will seek to notify you as promptly as
                    possible.</span>
            </p>

            <h4 class="my-5" id="sharing"><strong>J. SHARING INFORMATION</strong></h4>
            <p>
                <span>Information that we collect and process may be shared by either you or us in certain
                    circumstances.</span>
            </p>
            <p><span>If you sign up for our newsletter or if you register on our website, we will store some of your
                    information, including your email address, IP address and certain information about the links you
                    click within the emails we send you, on a Mailchimp server. Neither we nor Mailchimp will ever sell
                    your email address or share it with any other party, unless we are legally compelled to do so. If
                    you contact Mailchimp directly regarding your subscription to our newsletter, Mailchimp may contact
                    you directly; otherwise, Mailchimp will never contact you. Only authorised Mailchimp employees have
                    access to our subscriber list. You are always free to unsubscribe from our newsletter, but as long
                    as you are registered with </span><span> {{ Client }} </span><span> we may use Mailchimp to send you
                    information about your account. You can find information about our e-mail service provider&rsquo;s
                    privacy policies and practices at </span><a
                    href="https://mailchimp.com/legal/privacy"><span>https://mailchimp.com/legal/privacy</span></a><span>.</span>
            </p>
            <p><span>We may share personal data with third parties if we have good-faith belief that their access to and
                    use of the personal data is necessary:</span>
            </p>
            <ul>
                <li><span>to meet any applicable law and/or court order;</span></li>
                <li>
                    <span>to detect, prevent or otherwise address fraud, security or technical issues;
                        and/or&nbsp;</span>
                </li>
                <li><span>to protect the interests, properties or safety of us, our Users or the public, in accordance
                        with the law.&nbsp;</span>
                </li>
            </ul>
            <p><span>We will notify you about such disclosure, as far as reasonably possible.</span></p>
            <p>
                <span>In the event of alteration of the corporate structure of </span><span> {{ Client }} </span><span>,
                    including, but without limitation to, incorporation, merger, sale, liquidation or substantial
                    transfer of assets, </span><span> {{ Client }} </span><span>, may, at its sole discretion, transfer,
                    sell or assign personal data collected via this website, including, but without limitation to,
                    personally identifiable information and aggregated information about its Users, to one or more
                    subsidiaries.</span>
            </p>
            <p><span>Your personal data will not be disclosed to third parties without you being informed, or without
                    your consent, except in the circumstances expressly provided in this document. We may share personal
                    data with third parties for other reasons than the ones mentioned above, if we obtained your
                    explicit consent to do so. You have the right to withdraw this consent at any time.</span>
            </p>
            <p>
                <span>You can always report any suspected misuse of your personal information to us by emailing us at
                </span><span> {{ Email }} </span><span> and we will investigate your claim as soon as
                    practicable.</span>
            </p>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="location"><strong>K. LOCATION AND
                    TRANSFER</strong></h4>
            <p><span>We may need to share your information externally with third party service providers and developers
                    for the purposes and activities specified in this Privacy Notice. Consequently, we and our third
                    party service providers may transfer personal data to, or access it from, countries outside
                    User&rsquo;s country of domicile. This may involve information being collected within the European
                    Economic Area (&ldquo;EEA&rdquo;) being transferred to countries outside of the EEA, including
                    Australia, Canada, Hong Kong, Japan, New Zealand and United States.</span>
            </p>
            <p><span>We take steps to ensure that Users&rsquo; personal data receives an adequate level of protection in
                    the countries in which we process it. In case our processing is subject to any EU data protection
                    law and your personal data is transferred from the EEA to a service provider for processing in any
                    country outside the EEA that is not recognised by the EU Commission as providing an adequate level
                    of protection for personal data, we provide for appropriate safeguards by EU Commission&rsquo;s
                    standard contractual clauses or by any other appropriate safeguard as foreseen under the applicable
                    data protection law, in order to ensure that any transfer of your personal data outside of the EEA
                    will be lawful and safe.</span>
            </p>

            <h4 class="text-left font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="complaint"><strong>L.
                    FILING A COMPLAINT</strong></h4>
            <p><span>In case you consider our processing of your personal data to be inconsistent with applicable data
                    protection law, you may file a complaint with the local supervisory authority for data
                    protection.</span>
            </p>
            <p><span>&nbsp;The Portuguese Supervisory authority:</span></p>
            <p><span>Comiss&atilde;o Nacional de Prote&ccedil;&atilde;o de Dados (CNPD)</span></p>
            <p>
                <span>Address: Rua de S&atilde;o Bento, n&ordm;. 148 &ndash; 3&ordm;; 1200- 821 Lisboa; or via e-mail:
                </span><a href="mailto:geral@cnpd.pt"><span>geral@cnpd.pt</span></a></p>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="amendments"><strong>M. AMENDMENTS
                    TO THE PRIVACY NOTICE OF THIS
                    WEBSITE</strong>
            </h4>
            <p><span>We may update this Privacy Notice at any time if required in order to reflect changes in our data
                    processing practices, in personal data protection laws or otherwise. The current version can be
                    found on our website together with the corresponding publication date.</span>
            </p>
            <p><span>You should consult the Privacy Notice regularly, as you accept and are bound by its provisions. We
                    will, however, notify you of significant changes to this policy by email. If you do not agree with
                    alterations of the Privacy Policy, you should not continue to access our website or use our
                    Services.</span>
            </p>

            <h4 class="font-bold mb-1 font-blackMango text-md tracking-wide mt-5" id="contacts"><strong>N.
                    CONTACT</strong></h4>
            <p><span>If you have any question or request on this Privacy Notice or our privacy practices, you can
                    contact us:</span>
            </p>
            <ul>
                <li><span>by email at </span><span> {{ Email }} </span></li>
                <li><span>by mail at </span><span> {{ Address }} </span></li>
            </ul>
            <p>
                <span>This Privacy Notice was last updated on January 1</span><span>st</span><span>, 2020.&nbsp;</span>
            </p>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                ActiveShadow: true,
                Client: "Exclusivefamous, Unipessoal Lda.",
                Email: "agency@la-agency.com",
                Address: "Av. da Liberdade, 110 1269-046 Lisbon, PT",
                Website: "www.la-agency.com",
                Phone: "962 493 594*, (+351) 966 764 147*",
                Aviso: "*CALL NO NATIONAL MOBILE NETWORK",
                NIF: "513757651"
            };
        }
    }
</script>